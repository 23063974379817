<template>
  <div class="scroll">
    <div class="all-documents">
      <div class="text-documents">{{ $localization("web-folders-title-upper") }}</div>

      <div class="element" style="display: flex; align-items: center" :class="fileSearchFilter.FileCategoryId == item.ID ? 'active' : ''" v-for="(item, index) in fileCategories" :key="item.ID + index" @click="changeSelectedCategory(item.ID)">
        <div class="start">
          <i class="icon-folder" :style="'background-color:' + item.Color + ' !important'"></i>
        </div>
        <div class="middle">
          {{ item.Title }}
        </div>
        <div class="last">
          <span class="text-danger" v-on:click="deleteFolder(item.ID)">
            <div class="icon-close" v-if="user.Role == 10 || user.Role == 12" style="width: 22.54px; height: 22.54px"></div>
          </span>
        </div>
      </div>

      <div class="add-button-folders" v-if="user.Role == 10 || user.Role == 12">
        <el-button @click="showAddModal"><i></i></el-button>
      </div>
    </div>

    <el-dialog :visible.sync="addModal" custom-class="add-folder-dialog" :with-header="false" width="30%" :close-on-click-modal="false" :modal-append-to-body="false" :show-close="false">
      <div class="close" @click="addModal = false">
        <i class="icon-close"></i>
      </div>
      <el-form ref="form">
        <div class="all-parts">
          <div class="head-tag new-doc">{{ $localization("web-folders-new-record") }}</div>

          <div class="part input-container">
            <div class="text">{{ $localization("web-folders-order-no") }}</div>
            <div class="input"><el-input type="number" v-model="fileCategory.OrderNo"></el-input></div>
            <span class="error">{{ $errorMessage("OrderNo", fileValidationErrors) }}</span>
          </div>
          <div class="part input-container">
            <div class="text">{{ $localization("web-folders-title-text") }}</div>
            <el-input v-model="fileCategory.Title"></el-input>
            <span class="error">{{ $errorMessage("Title", fileValidationErrors) }}</span>
          </div>

          <div class="part input-container">
            <div class="text">{{ $localization("web-folders-color") }}</div>
            <el-color-picker v-model="fileCategory.Color"></el-color-picker>
            <div class="error">{{ $errorMessage("Color", fileValidationErrors) }}</div>
          </div>

          <div class="button-group-admin">
            <el-button class="cancelReportAdmin" @click="addModal = false">{{ $localization("web-folders-cancel") }}</el-button>
            <el-button class="confirmReportAdmin" type="primary" @click="save"><i class="icon-send"></i>{{ $localization("web-folders-save") }}</el-button>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import * as ActionNames from "@/store/action-names";
import * as MutationNames from "@/store/mutation-names";
export default {
  data() {
    return {
      addModal: false,
    };
  },
  computed: {
    fileValidationErrors() {
      return this.$store.getters.getFileValidationErrors;
    },
    user() {
      return this.$store.getters.getUser;
    },
    fileCategories() {
      return this.$store.getters.getFileCategories.Items;
    },
    fileSearchFilter() {
      return this.$store.getters.getFileSearchFilter;
    },
    fileCategory() {
      return this.$store.getters.getFileCategory;
    },
  },
  async beforeMount() {
    await this.$store.dispatch(ActionNames.GET_FILE_CATEGORIES, {});
    this.changeSelectedCategory(this.fileCategories.length > 0 ? this.fileCategories[0].ID : null);
  },
  methods: {
    changeSelectedCategory(Id) {
      this.$store.commit(MutationNames.CLEAR_FILE_SEARCH_FILTER);
      this.fileSearchFilter.Type = 2;
      if (Id) {
        this.fileSearchFilter.FileCategoryId = Id;
        this.$store.dispatch(ActionNames.GET_FILES, this.fileSearchFilter);
      } else {
        this.$store.commit(MutationNames.CLEAR_FILES);
      }
    },
    async deleteFolder(Id) {
      this.$confirm(this.$localization("web-folders-are-you-sure-to-delete-this"), this.$localization("web-folders-warning"), {
        confirmButtonText: this.$localization("web-folders-yes-delete"),
        cancelButtonText: this.$localization("web-folders-give-up"),
        type: "warning",
        customClass: "alert-dialog",
        confirmButtonClass: "primary-btn btn-shadow",
        cancelButtonClass: "secondarary-btn",
      })
        .then(async () => {
          await this.$store.dispatch(ActionNames.DELETE_FILE_CATEGORY, { Id: Id });
          this.changeSelectedCategory(this.fileCategories.length > 0 ? this.fileCategories[0].ID : null);
        })
        .catch(() => {});
    },
    showAddModal() {
      this.addModal = true;
      this.$store.commit(MutationNames.SET_FILE_VALIDATION_ERRORS, []);
      this.$store.commit(MutationNames.SET_FILE_CATEGORY, {
        Id: null,
        OrderNo: this.fileCategories.length + 1,
        Title: null,
        Color: null,
      });
    },
    async save() {
      var res = await this.$store.dispatch(ActionNames.ADD_UPDATE_FILE_CATEGORY, this.fileCategory);
      if (!res.HasError) {
        this.changeSelectedCategory(this.fileCategories.length > 0 ? this.fileCategories[this.fileCategories.length - 1].ID : null);
        this.addModal = false;
      }
    },
  },
};
</script>

<style lang="less">
.scroll {
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #eaedf0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #8697a8;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8697a8;
  }
}

.add-button-folders {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  button {
    height: 40px;
    width: 40px;
    background-color: #234a98;
    border: none;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);

    &:hover {
      background-color: #234a98;
      border-color: none;
      color: white;
    }

    i {
      width: 24px;
      height: 24px;
      display: block;
      mask: url("../../assets/img/icons/Plus.svg") no-repeat center;
      -webkit-mask: url("../../assets/img/icons/Plus.svg") no-repeat center;
      background-color: white;
    }
  }
}

.all-documents {
  background-color: white;
  height: calc(100vh - 65px);
  width: 100%;
  border-right: 1px solid #eaedf0;
  overflow: auto;

  .text-documents {
    padding: 20px 24px 10px 24px;
    color: #8697a8;
    font-weight: 500;
    font-size: 15px;
  }

  .element {
    width: 100%;

    & > span {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
      max-width: 100%;
    }

    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0;
    padding: 15px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #44566c;
    border: none;
    width: 100%;
    background-color: white;
    cursor: pointer;

    .middle {
      word-break: break-word;
    }

    .last {
      margin-left: auto;
      cursor: pointer;
    }

    .icon-folder {
      flex-shrink: 0;
      height: 22px;
      width: 22px;
    }

    &.active {
      position: relative;

      &::after {
        display: block;
        content: "";
        width: 4px;
        height: 100%;
        background-color: #234a98;
        left: 0;
        top: 0;
        border-radius: 0 5px 5px 0;
        position: absolute;
      }

      background: #f8fafb;
    }

    &:nth-child(3n-2) {
      .icon-folder {
        background-color: #44566c;
      }
    }

    &:nth-child(3n-1) {
      .icon-folder {
        background-color: #ff3d57;
      }
    }

    &:nth-child(3n) {
      .icon-folder {
        background-color: #2586c7;
      }
    }
  }
}

.add-folder-dialog {
  border-radius: 10px;

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
    position: relative;

    .close {
      position: absolute;
      right: 1rem;
      cursor: pointer !important;
      top: 1rem;
      width: 28px;
      height: 28px;
      padding: 3px;
      border-radius: 50%;
      background-color: #eaedf0;

      .icon-close {
        width: 100%;
        height: 100%;
      }
    }
  }

  padding: 20px;

  .all-parts {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-height: 86vh;
  }

  .head-tag {
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    color: #44566c;
  }

  .mid-tag {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #44566c;
  }

  .el-select,
  .el-input {
    width: 100%;
    height: 38px;
  }

  .part {
    .text {
      font-size: 15px;
      font-weight: 400;
      line-height: 17px;
      color: #8697a8;
      margin-bottom: 10px;

      .red {
        color: #ff3d57;
      }
    }

    .el-input__inner {
      padding: 12px 19px;
    }

    input,
    textarea {
      background-color: #f8fafb;
      font-size: 15px;
      font-weight: 400;
      color: #44566c;
      border: none;

      &:focus {
        border-color: inherit;
      }
    }

    .el-textarea {
      textarea {
        height: 150px;
        padding: 16px 18px;
      }
    }
  }
}
</style>
