<template>
  <div class="content" style="padding: 42px 30px">
    <div class="title">
      {{ $localization("web-content-files-text") }}
      <span class="tag-quantity" v-if="files.TotalCount > 0">({{ files.TotalCount }})</span>
    </div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>{{ $localization("web-content-files-text") }}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="currentCategory != null">{{ currentCategory.Title }}</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="buttons-group">
        <el-button
          class="grid"
          @click="
            listType = 1;
            multipleSelection = [];
          "
          ><i class="icon-grid"></i
        ></el-button>
        <el-button
          class="list"
          @click="
            listType = 0;
            gridCheckList = [];
          "
          ><i class="icon-list"></i
        ></el-button>
        <div v-if="multipleSelection.length > 0 && listType == 0">{{ multipleSelection.length }} {{ $localization("web-content-n-data-selected") }}</div>
        <div v-if="gridCheckList.length > 0 && listType == 1">{{ gridCheckList.length }} {{ $localization("web-content-n-data-selected") }}</div>
        <el-button class="delete" @click="deleteSelections" v-if="multipleSelection.length > 0 && listType == 0"><i class="icon-delete"></i></el-button>
        <el-button class="delete" @click="deleteSelections" v-if="gridCheckList.length > 0 && listType == 1"><i class="icon-delete"></i></el-button>
      </div>
    </div>

    <div class="bottom-header">
      <div class="left">
        <div class="date-inputs">
          <el-date-picker v-model="dateRange" align="center" type="daterange" range-separator="-" start-placeholder="..." end-placeholder="..." format="dd.MM.yyyy" prefix-icon="icon-calendar" @change="handleDateChange"> </el-date-picker>
        </div>
        <div class="drop-down">
          <el-select v-model="extension" :placeholder="$localization('web-content-file-format')" v-if="fileExtensions.length > 0" clearable @change="handleExtensionChange">
            <el-option v-for="item in fileExtensions" default-first-option:true :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </div>
      </div>
      <div class="right">
        <div class="search-bar">
          <div class="search">
            <el-input v-model="searchTerm" :placeholder="$localization('web-content-file-search')" clearable @change="handleSearch">
              <i slot="prefix" class="el-input__icon el-icon-search" style="color: #8697a8; font-weight: bold"></i>
            </el-input>
          </div>
        </div>
        <div class="add-button" @click="dialogVisible = true">
          <el-button><i></i></el-button>
        </div>
      </div>
    </div>

    <div class="all-data">
      <div class="list-table" v-if="listType == 0">
        <el-table ref="multipleTable" :data="files.Items" @selection-change="handleSelectionChange">
          <el-table-column sortable type="selection" align="center" style="text-center" width="50"> </el-table-column>
          <el-table-column sortable prop="name" label="Ad">
            <template slot-scope="scope">
              <img :src="getExtension(scope.row.Extension)" />
              {{ scope.row.FilenameReal }}
            </template>
          </el-table-column>
          <el-table-column sortable prop="size" :label="$localization('web-content-size-upper')" width="120">
            <template slot-scope="scope">
              {{ fileSize(scope.row.FileSizeInMb) }}
            </template>
          </el-table-column>
          <el-table-column sortable prop="type" :label="$localization('web-content-type-upper')" width="100">
            <template slot-scope="scope">
              {{ scope.row.Extension }}
            </template>
          </el-table-column>
          <el-table-column sortable :label="$localization('web-content-date-upper')" width="120">
            <template slot-scope="scope">{{ $moment(scope.row.CreatedDateTime).format("Do.MM.YYYY") }}</template>
          </el-table-column>
          <el-table-column align="center" label="" width="80">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link actions-menu">
                  <i class="icon-dots"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <span @click="generatePresignedUrl(scope.row)" style="display: block">{{ $localization("web-content-show-file") }}</span>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="user.Role == 10 || user.Role == 12"
                    ><span class="tex-danger" style="display: block" @click="deleteFile(scope.row)">{{ $localization("web-content-delete") }}</span></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <template slot="empty">
            <div class="lottie-container">
              <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
              <div class="no-data-text">{{ $localization("web-content-no-data-found") }}</div>
              <div class="no-data-text">{{ $localization("web-content-no-file-data-found") }}</div>
            </div>
          </template>
        </el-table>
      </div>
      <div class="grid-system" v-if="listType == 1">
        <div class="files">
          <!-- <div class="text">Dosyalar</div> -->
          <div class="card" style="float: left; margin-right: 15px; margin-bottom: 15px" v-for="item in files.Items.filter((x) => x.Type == 2)" :key="item.ID">
            <div class="top">
              <el-checkbox @change="addGridList(item.ID)" :checked="gridCheckList.some((k) => k == id)"></el-checkbox>
              <el-button class="three-dots">
                <el-dropdown>
                  <span class="el-dropdown-link actions-menu">
                    <i class="icon-dots"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <span @click="generatePresignedUrl(item)" style="display: block">{{ $localization("web-content-show-file") }}</span>
                    </el-dropdown-item>
                    <el-dropdown-item divided v-if="user.Role == 10 || user.Role == 12"
                      ><span class="tex-danger" style="display: block" @click="deleteFile(item)">{{ $localization("web-content-delete") }}</span></el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </el-button>
            </div>
            <div class="file-type">
              <div class="type">
                <img :src="getExtension(item.Extension)" />
              </div>
            </div>
            <div class="file-name text-center" style="text-align: center; font-size: 14px">
              {{ item.FilenameReal }}
            </div>
            <div class="bot">
              <div class="data-size">
                {{ fileSize(item.FileSizeInMb) }}
              </div>
              <div class="data-date">
                {{ $moment(item.CreatedDateTime).format("Do.MM.YYYY") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="upload-folder">
      <el-dialog :title="$localization('web-folders-upload-folder')" :visible.sync="dialogVisible" width="507px" :close-on-click-modal="false">
        <div class="upload-area part">
          <el-upload
            class="upload-demo"
            drag
            multiple
            :action="$client.defaults.baseURL + 'Account/UploadFile?' + 'type=2' + '&FileCategoryId=' + currentCategory?.ID"
            :headers="{
              Authorization: $client.defaults.headers.common['Authorization'],
            }"
            :on-success="fileSuccess"
            :on-error="fileError">
            <!--:show-file-list="false"-->
            <i class="el-icon-upload"></i>
            <div class="el-upload__text" v-html="$localization('web-folders-upload-folder-text')"></div>
          </el-upload>
        </div>
        <!--
        <div class="uploading-all-documents part">
          <ul class="file-list">
            <li class="list-item" v-for="(item, index) in $store.state.filemanager.uploadDocuments" :key="index">
              <div class="file">
                <img :src="getExtension(item.Extension)" />
              </div>
              <div class="details">
                <div class="title">{{ item.FilenameReal }}</div>
                <div class="byte">{{ fileSize(item.FileSizeInMb) }}</div>
              </div>
              <div class="icon-download" @click="downloadFiles(item.ObjectKey)"></div>
            </li>
          </ul>
        </div>
        <div class="button-group-admin">
          <el-button class="cancelReportAdmin" @click="dialogVisible = false">{{ $localization("web-content-cancel") }}</el-button>
          <el-button class="confirmReportAdmin" type="primary" @click="dialogVisible = false"><i class="icon-send"></i>{{ $localization("web-content-save") }}</el-button>
        </div>-->
      </el-dialog>
    </div>
  </div>
</template>

<script>
import * as ActionNames from "@/store/action-names";
export default {
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    fileSearchFilter() {
      return this.$store.getters.getFileSearchFilter;
    },
    files() {
      return this.$store.getters.getFiles;
    },
    currentCategory() {
      return this.fileSearchFilter.FileCategoryId != null ? this.$store.getters.getFileCategories.Items.find((x) => x.ID == this.fileSearchFilter.FileCategoryId) : null;
    },
  },
  data() {
    return {
      listType: 0,
      fileExtensions: [],
      multipleSelection: [],
      gridCheckList: [],

      fileList: [],
      tableData: [],
      SearchTimeout: null,

      dialogVisible: false,
      dateRange: null,
      extension: null,
      searchTerm: null,
    };
  },
  beforeMount() {
    this.fileExtensions = "xlsx,pptx,doc,docx,png,jpg,jpeg,mp4,mp3,pdf".split(",").map((x) => ({
      value: "." + x,
      label: "." + x,
    }));
  },
  methods: {
    addGridList(id) {
      if (this.gridCheckList.some((k) => k == id)) {
        var index = this.gridCheckList.indexOf(id);
        this.gridCheckList.splice(index, 1);
      } else {
        this.gridCheckList.push(id);
      }
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    fileSuccess(res) {
      if (!res.HasError) {
        this.files.Items.push(res.Item);
        // this.files.Items.push  yerine dosyalar tekrar apiden çekilebilir.(Daha sağlıklı fakat maliyetli olur.)
        /*await this.$store.dispatch(ActionNames.GET_FILES, this.fileFilter);*/
      } else {
        this.$message.error(res.Message);
      }
    },
    fileError(res) {
      this.$message.error(res.toString());
    },
    deleteSelections() {
      this.$confirm(this.$localization("web-content-are-you-sure-to-selected-files"), this.$localization("web-content-warning"), {
        confirmButtonText: this.$localization("web-content-yes-delete"),
        cancelButtonText: this.$localization("web-content-give-up"),
        type: "warning",
        customClass: "alert-dialog",
        confirmButtonClass: "primary-btn btn-shadow",
        cancelButtonClass: "secondarary-btn",
      })
        .then(async () => {
          var res = null;
          if (this.listType == 0) {
            var idListesi = this.multipleSelection.map(function (item) {
              return item.ID;
            });
            res = await this.$store.dispatch(ActionNames.DELETE_FILES, { IDs: idListesi });
            if (!res.HasError) {
              this.multipleSelection = [];
            }
          } else {
            res = await this.$store.dispatch(ActionNames.DELETE_FILES, { IDs: this.gridCheckList });
            if (!res.HasError) {
              this.gridCheckList = [];
            }
          }
          await this.$store.dispatch(ActionNames.GET_FILES, this.fileSearchFilter);
        })
        .catch(() => {});
    },
    async deleteFile(item) {
      this.$confirm(this.$localization("web-content-are-you-sure-to-selected-files"), this.$localization("web-content-warning"), {
        confirmButtonText: this.$localization("web-content-yes-delete"),
        cancelButtonText: this.$localization("web-content-give-up"),
        type: "warning",
        customClass: "alert-dialog",
        confirmButtonClass: "primary-btn btn-shadow",
        cancelButtonClass: "secondarary-btn",
      })
        .then(async () => {
          var res = await this.$store.dispatch(ActionNames.DELETE_FILES, {
            IDs: [item.ID],
          });
          if (!res.HasError) {
            await this.$store.dispatch(ActionNames.GET_FILES, this.fileSearchFilter);

            if (this.listType == 0) {
              this.multipleSelection = this.multipleSelection.filter((x) => x.ID !== item.ID);
            } else {
              this.gridCheckList = this.gridCheckList.filter((x) => x != item.ID);
            }
          }
        })
        .catch(() => {});
    },
    async generatePresignedUrl(item) {
      var res = await this.$store.dispatch("generatePreSignedUrl", {
        ObjectKey: item.ObjectKey,
      });
      if (!res.HasError) {
        window.open(res.Data.Url, "_blank");
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //#region Date Picker
    handleDateChange(date) {
      if (date != null) {
        this.fileSearchFilter.CreatedAtMin = date[0];
        this.fileSearchFilter.CreatedAtMax = date[1];
      } else {
        this.fileSearchFilter.CreatedAtMin = null;
        this.fileSearchFilter.CreatedAtMax = null;
      }
      this.$store.dispatch(ActionNames.GET_FILES, this.fileSearchFilter);
    },
    //#endregion
    //#region Extension Dropdown
    handleExtensionChange(value) {
      if (value) {
        this.fileSearchFilter.Extensions = [value];
      } else {
        this.fileSearchFilter.Extensions = null;
      }
      this.$store.dispatch(ActionNames.GET_FILES, this.fileSearchFilter);
    },
    //#endregion
    //#region Search Bar
    handleSearch(value) {
      if (value) {
        this.fileSearchFilter.SearchTerm = value.toString();
      } else {
        this.fileSearchFilter.SearchTerm = null;
      }
      this.$store.dispatch(ActionNames.GET_FILES, this.fileSearchFilter);
    },
    //#endregion
  },
};
</script>

<style lang="less">
.text-danger {
  color: red;
}

.actions-menu:hover {
  cursor: pointer;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #234a98;
  border-color: #234a98;
}

.el-checkbox__inner:hover {
  border-color: #234a98;
}

.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #234a98;
}

.content-2 {
  height: calc(100vh - 66px);
  overflow: auto;
}

.content {
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #f1f1f1;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8697a8;
  }

  padding: 10px 30px 10px 30px;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 30px;
    color: #44566c;
    font-weight: 700;
    line-height: 35px;
    padding-bottom: 22px;

    span {
      color: #8697a8;
    }
  }

  .breadcrumb {
    .buttons-group {
      display: flex;
      gap: 24px;
      align-items: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: #eaedf0;
        border: none;

        i {
          background: #44566c;
        }
      }
    }
  }

  .bottom-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 22px;

    .left {
      display: flex;
      align-items: center;
      gap: 20px;

      .date-inputs {
        .el-date-editor--daterange.el-input__inner {
          width: 225px;
          height: 40px;
          align-items: center;
          filter: drop-shadow(0px 4px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 8px 16px rgba(169, 194, 209, 0.1));
          border: none;
          border-radius: 5px;

          ::placeholder {
            color: #44566c;
            font-size: 15px;
            font-weight: 400;
          }

          i {
            line-height: unset;
          }

          .icon-calendar {
            padding: 0 15px;
            background-color: #44566c;
          }
        }
      }

      .drop-down {
        .el-select {
          .el-input {
            width: 145px;
            height: 40px;

            .el-input__inner {
              filter: drop-shadow(0px 4px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 8px 16px rgba(169, 194, 209, 0.1));
              border: none;
              border-radius: 5px;
            }

            ::placeholder {
              color: #44566c;
              font-size: 15px;
              font-weight: 400;
            }

            i {
              background-color: rgba(255, 255, 255, 0);
            }
          }
        }
      }

      .show-people {
        display: flex;
        align-items: center;
        gap: 30px;
        font-size: 15px;
        font-weight: 400;
        color: #44566c;

        .text {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #44566c;
        }

        .el-select {
          .el-input {
            width: 75px;
            height: 40px;
          }
        }
      }
    }

    .right {
      align-items: center;
      display: flex;
      gap: 16px;

      .search-bar {
        .search {
          .el-input {
            width: 290px;
            height: 40px;

            input {
              padding-left: 40px;
              filter: drop-shadow(0px 4px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 8px 16px rgba(169, 194, 209, 0.1));
              border: none;
              border-radius: 5px;
            }

            ::placeholder {
              color: #8697a8;
              font-size: 15px;
              font-weight: 400;
            }

            i {
              color: #8697a8;
              font-size: 15px;
              background-color: rgba(255, 255, 255, 0);
              padding: 0 10px;
            }
          }
        }
      }

      .add-button {
        button {
          height: 40px;
          width: 40px;
          background-color: #234a98;
          border: none;
          border-radius: 50%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);

          i {
            width: 24px;
            height: 24px;
            display: block;
            mask: url("../../assets/img/icons/Plus.svg") no-repeat center;
            -webkit-mask: url("../../assets/img/icons/Plus.svg") no-repeat center;
            background-color: white;
          }
        }
      }
    }
  }

  .all-data {
    .list-table {
      .el-table {
        background-color: rgba(255, 255, 255, 0) !important;
        box-shadow: none;

        &::before {
          height: 0;
        }
      }

      .el-table tr {
        background-color: rgba(255, 255, 255, 0);
      }

      table {
        border-spacing: 0px 12px !important;
        border-collapse: separate;
      }

      table td {
        border: none;
      }

      .el-table .el-table__body-wrapper table tr td:last-child {
        color: #b3c0ce;
      }

      .el-table__header {
        border-spacing: 0px 0px !important;
        border-collapse: separate;
        padding-bottom: 0;
        padding-top: 0;
      }

      thead {
        th {
          border: none !important;
          background-color: rgba(255, 255, 255, 0) !important;
        }

        .cell {
          font-weight: 500;
        }
      }

      tbody {
        height: auto;

        .el-table__row {
          filter: drop-shadow(0px 2px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 12px 16px rgba(169, 194, 209, 0.1));

          &:hover {
            background-color: white !important;
          }

          td {
            height: 60px;
          }

          td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          td:last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
          }

          background-color: white !important;
          border-radius: 10px !important;
        }

        .el-button {
          border: none;
        }

        .el-table-column--selection {
          .cell {
            justify-content: center !important;
          }
        }

        td {
          .cell {
            display: flex;
            align-items: center;
            gap: 10px;

            img {
              height: 40px;
            }
          }
        }

        .three-dots {
          background-color: inherit;

          i {
            height: 25px;
            width: 25px;
          }
        }
      }
    }

    .grid-system {
      .text {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: #44566c;
        margin: 20px 0;
      }

      .card {
        height: 200px;
        width: 200px;
        background-color: white;
        border-radius: 20px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        filter: drop-shadow(0px 2px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 12px 16px rgba(169, 194, 209, 0.1));

        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .el-checkbox {
            width: 20px;
            height: 20px;

            .el-checkbox__input {
              .el-checkbox__inner {
                border-radius: 50px;

                &:hover {
                  border-color: #eaedf0;
                }
              }

              &.is-checked {
                .el-checkbox__inner {
                  background-color: #234a98;
                  border-color: #234a98;
                }
              }
            }
          }

          .three-dots {
            background-color: inherit;

            i {
              height: 25px;
              width: 25px;
            }
          }

          .el-button {
            border: none;
            height: 25px;
            width: 25px;
            padding: 0;
          }
        }

        .file-type {
          display: flex;
          justify-content: center;
          align-items: center;

          .type {
            height: 80px;
            width: 80px;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
              height: 100%;
              width: 100%;
            }
          }
        }

        .file-name {
          display: flex;
          justify-content: center;
        }

        .bot {
          display: flex;
          justify-content: space-between;

          div {
            color: #8697a8;
            font-size: 13px;
          }
        }
      }
    }
  }

  #upload-folder {
    .el-dialog {
      border-radius: 10px;
      padding: 20px;

      .el-dialog__body {
        padding: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 10px;
      }

      filter: drop-shadow(0px 4px 8px rgba(169, 194, 209, 0.25)) drop-shadow(0px 16px 32px rgba(169, 194, 209, 0.25));

      .el-dialog__title {
        color: #44566c;
        font-weight: 700;
        font-size: 30px;
        line-height: 35px;
      }

      .el-dialog__headerbtn {
        background-color: #eaedf0;
        height: 28px;
        width: 28px;
        border-radius: 50%;

        .el-dialog__close.el-icon.el-icon-close {
          display: flex;
          align-items: center;
          justify-content: center;

          &:before {
            content: "";
            width: 22px;
            height: 22px;
            display: block;
            mask: url("../../assets/img/icons/Cross.svg") no-repeat center;
            background-color: #44566c;
          }
        }
      }

      .part {
        margin-bottom: 0;
      }

      .upload-area {
        display: flex;
        justify-content: center;

        .upload-demo {
          .el-upload {
            .el-upload-dragger {
              background-color: #eaedf0;
              width: 420px;
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 5px;

              i {
                margin: 0;
                font-size: 30px;
                line-height: 0;
              }

              .el-upload__text {
                font-size: 15px;
                color: #44566c;
                font-weight: 400;

                em {
                  color: #234a98;
                }
              }
            }
          }

          .el-upload-list {
            background-color: #f8fafb;
          }
        }
      }

      .uploading-all-documents {
        max-height: 257px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .document {
          width: 420px;
          height: 75px;
          background-color: #f8fafb;
          margin: 8px 0;
        }

        .still-uplaoading {
          display: flex;
          gap: 26px;
          padding: 18px 16px 16px 16px;

          .file-type {
            i {
              width: 31px;
              height: 40px;
            }
          }

          .right {
            width: 100%;

            .top {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .upload-info {
                color: #44566c;
                font-size: 13px;
                font-weight: 400;
              }

              .cancel-button {
                background-color: #44566c;
                height: 14px;
                width: 14px;
                border-radius: 50%;
                padding: 12px;
                display: inline-flex;
                align-items: center;
                justify-content: center;

                i {
                  width: 8px;
                  height: 8px;
                  display: block;
                  mask: url("../../assets/img/icons/Cross.svg") no-repeat center;
                  -webkit-mask: url("../../assets/img/icons/Cross.svg") no-repeat center;
                  background-color: white;
                }
              }
            }
          }
        }

        .uploaded {
          display: flex;
          gap: 26px;
          padding: 18px 16px 16px 16px;

          .file-type {
            i {
              width: 31px;
              height: 40px;
            }
          }

          .right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .texts {
              .document-name {
                color: #44566c;
                font-size: 13px;
                font-weight: 400;
              }

              .document-size {
                color: #8697a8;
                font-size: 13px;
                font-weight: 400;
                margin-top: 4px;
              }
            }

            .delete {
              button {
                border: none;
                background-color: inherit;
                padding: 0;

                i {
                  font-size: 22px;
                  color: #8697a8;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
